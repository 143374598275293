module.exports = {

	DEFAULT_SUBSITEID: 1,  //默认分站id
	DEFAULT_SUBSITENAME: "gz",  //默认分站名

	LOGIN_STATUS: 'LOGIN_STATUS',
	LOGIN_ACCOUNTKEY: 'LOGIN_ACCOUNTKEY',

	MULTIPLE_SPECIFICATION_NAME: '含多个规格',

	V_ERRORCODE_NONE: 0, // 没有错误
	V_ERRORCODE_NORMAL_ERROR: 1, //操作执行出现系统性错误
	V_ERRORCODE_NORMAL_SELLERNEEDLOGIN: 3, //提示信息,出现商家必须登陆以后才能操作

	WECHAT_APPID: "wx1dda1ade3f4bf60a", // 公众号appid
	WECHAT_REDIRECT: window.location.protocol + "//mall.okguo.com", // 授权回调域名
	
	HTTP_REQUEST_URL: window.location.protocol + "//" + window.location.host,

	CHANNEL_LOGIN_STATUS: 'CHANNEL_LOGIN_STATUS',

	// 远程资源域名
	REMOTE_RESOURCE_DOMAIN: process.env.VUE_APP_RESDATA_DOMAIN,

	// 有效分站
	SUBSITE_NAME: [
		"ka",
		"gz",
		"hd",
		"gb",
	],

	// 缓存时间 0 永久
	EXPIRE: 0,
	//分页最多显示条数
	LIMIT: 10,
};
