import request from '../common/utils/request'

/**
 * 获取商品列表
 * @param {*} params
 * @param loadingConfig 显示Toast.loading (false:不显示, string: 文字, object: Toast.loading参数);
 * @returns
 */
export function listGoods(params = {}, loadingConfig = false) {
    return request.get('/goods/?act=listGoods', params, {}, loadingConfig);
}

/**
 * 获取热门商品列表(猜你喜欢)
 * @param {*} params
 * @returns
 */
export function listHotGoods(params = {}, loadingConfig = false) {
    return request.get('/goods/?act=listHotGoods', params, {}, loadingConfig);
}

/**
 * 获取商家今天已购商品数量统计
 * @param {*} params
 * @returns
 */
export function listTodaySellerGoodsCount(params = {}, loadingConfig = false) {
    return request.get('/goods/?act=listTodaySellerGoodsCount', params, {}, loadingConfig);
}

/**
 * 商品行情
 * @param params
 * @returns {Promise<unknown>}
 */
export function listMarketQuotation(params = {}, loadingConfig = false) {
    return request.get("/marketQuotation/?act=listMarketQuotation", params, {}, loadingConfig);
}

/**
 * 商品详情
 * @param params
 * @returns {Promise<unknown>}
 */
export function getGoodsDetail(params = {}, loadingConfig = false) {
    return request.get("/goods/?act=getGoodsDetail", params, {}, loadingConfig);
}

/**
 * 预售商品详情
 * @param params
 * @returns {Promise<unknown>}
 */
export function getPresaleGoodsDetail(params = {}) {
    return request.get("/goods/?act=getPresaleGoodsDetail", params);
}

/**
 * 修改预售订单
 * @param params
 * @returns {Promise<unknown>}
 */
export function editGoodspPresaleOrder(params = {}) {
    return request.get("/goods/?act=editGoodspPresaleOrder", params);
}

/**
 * 限时销售商品提醒
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function addTimeLimitGoodsNotice(params = {}, loadingConfig = false) {
    return request.post("/goods/?act=addTimeLimitGoodsNotice", params, {}, loadingConfig);
}