import Vue from "vue";
import { createStore } from "vuex";
import modules from "./modules";
import getters from "./getters";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  strict: debug,
  modules,
  getters,
})