import request from '../common/utils/request'

//-----------login---------------登录相关
/**
 * 获取登录用户信息
 * @param {*} params
 * @returns 
 */
export function getLoginInfo(params = {}, loadingConfig = false) {
    return request.get('/login/?act=getLoginInfo', params, {}, loadingConfig);
}
/**
 * 全局登录
 * @param {*} params
 * @returns
 */
export function globalLogin(params = {}, loadingConfig = false) {
    return request.post("/login/?act=globalLogin", params, {}, loadingConfig);
}
/**
 * 退出登录
 * @param {*} params
 * @returns
 */
export function loginOut(params = {}, loadingConfig = false) {
    return request.post("/login/?act=logout", params, {}, loadingConfig);
}
/**
 * 获取登录验证码
 * @param {*} params
 * @returns
 */
export function getLoginCode(params = {}, loadingConfig = false) {
    return request.get("/login/?act=getLoginCode", params, {}, loadingConfig);
}
/**
 * 重定向到其他子系统
 * @param {*} params
 * @returns
 */
export function redirectToDomainUrl(params = {}, loadingConfig = false) {
    return request.get("/login/?act=redirectToDomainUrl", params, {}, loadingConfig);
}
//-----------login-------END--------登录相关

//-----------regisrer---------------注册先关
/**
 * 检查登陆帐号
 * @param {*} params
 * @returns
 */
export function checkLoginName(params = {}, loadingConfig = false) {
    return request.post('/register/?act=checkloginname', params, {}, loadingConfig);
}

/**
 * 检查登陆帐号是否已存在
 * @param {*} params
 * @returns
 */
export function checkExistSellerName(params = {}, loadingConfig = false) {
    return request.post("/register/?act=checkExistSellerName", params, {}, loadingConfig);
}

/**
 * 发送注册验证码
 * @param {*} params
 * @returns
 */
export function sendRegCode(params = {}, loadingConfig = false) {
    return request.get("/register/?act=sendregcode", params, {}, loadingConfig);
}

/**
 * 注册
 * @param {*} params
 * @returns
 */
export function sellerGlobalReg(params = {}, loadingConfig = false) {
    return request.post("/register/?act=sellerglobalreg", params, {}, loadingConfig);
}
//-----------regisrer-------END--------注册先关

//-----------password------------------密码相关
/**
 * 发送验证码
 * @param {*} params
 * @returns
 */
export function sendResetPwdCode(params = {}, loadingConfig = false) {
    return request.get("/password/?act=sendResetPwdCode", params, {}, loadingConfig);
}

/**
 * 检测验证码
 * @param {*} params
 * @returns
 */
export function checkResetPwdCode(params = {}, loadingConfig = false) {
    return request.post("/password/?act=checkResetPwdCode", params, {}, loadingConfig);
}

/**
 * 重置密码
 * @param {*} params
 * @returns
 */
export function resetPassword(params = {}, loadingConfig = false) {
    return request.post("/password/?act=resetPassword", params, {}, loadingConfig);
}
//-----------password-------END--------密码相关

//-----------securitycode--------------图片验证码相关
/**
 * 检测图片验证码
 * @param {*} params
 * @returns
 */
export function checkVerifyCode(params = {}, loadingConfig = false) {
    return request.get("/securitycode/?act=checkVerifyCode", params, {}, loadingConfig);
}
//-----------securitycode-------END------图片验证码相关

/**
 * 微信公众号自动登录
 * @param {*} params
 * @returns
 */
export function autoLoginByWx(params = {}, loadingConfig = false) {
    return request.post("/login/?act=autoLoginByWx", params, {}, loadingConfig);
}