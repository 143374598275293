import request from '../common/utils/request'

export const goodsCarApi = {

    /**
     * 编辑购物车商品(包含新增)
     * @param {*} params = {
     *     "gspid":商品规格ID,
     *     "num": 商品数量,
     * }
     * @returns
     */
    editGoods: function (params = {}, loadingConfig = false) {
        return request.post('/goodsCar/?act=editGoods', params, {}, loadingConfig);
    },

    /**
     * 获取购物车的商品数量与购物车数据
     * @param {*} params
     * @returns
     */
    getGoodsCarGoodsNum: function (params = {}, loadingConfig = false) {
        return request.get('/goodsCar/?act=getGoodsNum', params, {}, loadingConfig);
    },

    /**
     * 购物车商品列表
     * @param {*} params
     * @returns
     */
    listGoods: function (params = {}, loadingConfig = false) {
        return request.get('/goodsCar/?act=listGoods', params, {}, loadingConfig);
    },

    /**
     * 编辑或删除购物车商品
     * @param {*} params = {
     *     goodsData: 商品信息
     * }
     * @returns
     */
    editOrDeleteGoods: function (params = {}, loadingConfig = false) {
        return request.post('/goodsCar/?act=editOrDeleteGoods', params, {}, loadingConfig);
    },

}

export default goodsCarApi;