const state = {
    subSiteName: "",
    currentTimes: 0, // 当前时间戳(从服务器传递过来)
}

const mutations = {
    setSubSiteName(state, val) {
        state.subSiteName = val
    },
    SET_CURRENT_TIMES: (state, val) => {
        state.currentTimes = parseInt(val);
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}