import request from '../common/utils/request'

/**
 * 初始化页面
 * @param {*} params
 * @returns
 */
export function completeInfoView(params = {}, loadingConfig = false) {
    return request.get("/channel/completeinfo/", params, {}, loadingConfig);
}

/**
 * 获取渠道商用户信息
 */
export function getChannelLoginInfo(params = {}, loadingConfig = false) {
    return request.post("/channel/login/?act=getChannelLoginInfo", params, {}, loadingConfig);
}

/**
 * 全局登录
 * @param {*} params
 * @returns
 */
export function channelLogin(params = {}, loadingConfig = false) {
    return request.post("/channel/login/?act=login", params, {}, loadingConfig);
}
/**
 * 退出登录
 * @param {*} params
 * @returns
 */
export function channelLoginOut(params = {}, loadingConfig = false) {
    return request.post("/channel/login/?act=logout", params, {}, loadingConfig);
}
/**
 * 获取登录验证码
 * @param {*} params
 * @returns
 */
export function getChannelLoginCode(params = {}, loadingConfig = false) {
    return request.get("/channel/login/?act=getLoginCode", params, {}, loadingConfig);
}

/**
 * 注册
 * @param {*} params
 * @returns
 */
export function register(params = {}, loadingConfig = false) {
    return request.post("/channel/register/?act=register", params, {}, loadingConfig);
}

/**
 * 新增渠道商
 * @param {*} params
 * @returns
 */
export function addChannel(params = {}, loadingConfig = false) {
    return request.post("/channel/completeinfo/?act=newchannel", params, {}, loadingConfig);
}

/**
 * 上传文件
 * @param {*} params
 * @returns
 */
export function uploadImage(url = "",params = {}, loadingConfig = false) {
    if(!url || url == ""){
        url = "/channel/filemanagerapi/?act=uploadimage&rotate=1" ;
    }
    return request.post(url, params, {}, loadingConfig);
}

//-----------password------------------密码相关
/**
 * 发送验证码
 * @param {*} params
 * @returns
 */
export function sendResetPwdCode(params = {}, loadingConfig = false) {
    return request.get("/channel/password/?act=sendResetPwdCode", params, {}, loadingConfig);
}

/**
 * 检测验证码
 * @param {*} params
 * @returns
 */
export function checkResetPwdCode(params = {}, loadingConfig = false) {
    return request.post("/channel/password/?act=checkResetPwdCode", params, {}, loadingConfig);
}

/**
 * 重置密码
 * @param {*} params
 * @returns
 */
export function resetPassword(params = {}, loadingConfig = false) {
    return request.post("/channel/password/?act=resetPassword", params, {}, loadingConfig);
}
//-----------password-------END--------密码相关

/**
 * 获取分类别名列表
 * @param {*} params
 * @returns
 */
export function listCatalog(params = {}, loadingConfig = false) {
    return request.post("/channel/goods/?act=listCatalog", params, {}, loadingConfig);
}

/**
 * 获取商品列表
 * @param {*} params
 * @returns
 */
export function listGoods(params = {}, loadingConfig = false) {
    return request.get("/channel/goods/?act=listGoods", params, {}, loadingConfig);
}

/**
 * 获取渠道商商品列表
 * @param {*} params
 * @returns
 */
export function listChannelGoods(params = {}, loadingConfig = false) {
    return request.get("/channel/goods/?act=listchannelgoods", params, {}, loadingConfig);
}
/**
 * 发布商品
 * @param {*} params
 * @returns
 */
export function publishChannelGoods(params = {}, loadingConfig = false) {
    return request.post("/channel/goods/?act=publishgoods", params, {}, loadingConfig);
}