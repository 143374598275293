import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    //首页
    path: "/:cityname/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },
  {
    //个人中心
    path: "/:cityname/usercenter/",
    name: "Usercenter",
    component: () => import("@/views/usercenter/Index.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //充值
    path: "/:cityname/sellerrecharge/",
    name: "Sellerrecharge",
    component: () => import("@/views/usercenter/Sellerrecharge.vue"),
    meta: {
      requireAuth: false,
      requireInfo : true,
    }
  },
  {
    //个人中心->账单
    path: "/:cityname/sellerbills/",
    name: "Sellerbills",
    component: () => import("@/views/usercenter/Sellerbills.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->发货单
    path: "/:cityname/sellerbillsdetail/",
    name: "Sellerbillsdetail",
    component: () => import("@/views/usercenter/Sellerbillsdetail.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //实名认证（填写店铺信息，上传认证信息，完成认证）
    path: "/:cityname/sellercompleteinfo/",
    name: "SellerCompleteInfo",
    component: () => import("@/views/usercenter/SellerCompleteInfo.vue"),
    meta: {
      requireAuth: true,
    }
  },
  {
    //在线客服
    path: "/:cityname/customerservice/",
    name: "CustomerService",
    component: () => import("@/views/usercenter/CustomerService.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->优惠券
    path: "/:cityname/sellercoupons/",
    name: "Sellercoupons",
    component: () => import("@/views/usercenter/Sellercoupons.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->地址管理
    path: "/:cityname/sellerinfo/",
    name: "Sellerinfo",
    component: () => import("@/views/usercenter/Sellerinfo.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->我的预定
    path: "/:cityname/sellerpresale/",
    name: "Sellerpresale",
    component: () => import("@/views/usercenter/Sellerpresale.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->意见反馈
    path: "/:cityname/feedback/",
    name: "Feedback",
    component: () => import("@/views/usercenter/Feedback.vue"),
  },
  {
    //个人中心->帐户设置
    path: "/:cityname/sellerinfohome/",
    name: "Sellerinfohome",
    component: () => import("@/views/usercenter/Sellerinfohome.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->帐户设置->修改手机
    path: "/:cityname/sellermobile/",
    name: "Sellermobile",
    component: () => import("@/views/usercenter/Sellermobile.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->帐户设置->修改密码
    path: "/:cityname/sellerpwd/",
    name: "Sellerpwd",
    component: () => import("@/views/usercenter/Sellerpwd.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->绑定微信
    path: "/:cityname/bindweixinaccount/",
    name: "Bindweixinaccount",
    component: () => import("@/views/usercenter/Bindweixinaccount.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->快捷下单
    path: "/:cityname/quickorder/",
    name: "Quickorder",
    component: () => import("@/views/usercenter/Quickorder.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->我的订单
    path: "/:cityname/sellerorder/",
    name: "Sellerorder",
    component: () => import("@/views/usercenter/Sellerorder.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->我的订单->订单详情
    path: "/:cityname/sellerorderdetail/",
    name: "Sellerorderdetail",
    component: () => import("@/views/usercenter/Sellerorderdetail.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //个人中心->资料补充
    path: "/:cityname/infosupply/",
    name: "Infosupply",
    component: () => import("@/views/usercenter/Infosupply.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    }
  },
  {
    //单独的查看白单页面,通过短信发送地址给商家手机
    path: "/:cityname/usercenter/baidanviewer/",
    name: "BaiDanViewer",
    component: () => import("@/views/usercenter/BaiDanViewer.vue"),
    meta: {
      requireAuth: true,
      requireInfo : false,
    }
  },
  {
    //登陆页面
    path: "/:cityname/login/",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    //注册页面
    path: "/:cityname/register/",
    name: "Register",
    component: () => import("@/views/login/Register.vue"),
  },
  { //兼容旧版路由，后续统一后可删除
    path: "/:cityname/sellerreg/",
    name: "Sellerreg",
    component: () => import("@/views/login/Register.vue"),
  },
  {
    //登陆页面->忘记密码
    path: "/:cityname/password/",
    name: "Password",
    component: () => import("@/views/login/Password.vue"),
  },
  {
    //常购清单
    path: "/:cityname/regularpurchaselist/",
    name: "RegularPurchaseList",
    component: () => import("@/views/RegularPurchaseList.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    },
  },
  {
    //分类
    path: "/:cityname/catalog/",
    name: "Catalog",
    component: () => import("@/views/Catalog.vue")
  },
  { // 兼容旧版路由，首页banner跳转 (新版完全上线以后设置banner跳转路径后可以移除该路由
    path: "/:cityname/goods/",
    name: "Goods",
    component: () => import("@/views/Catalog.vue")
  },
  {
    //购物车
    path: "/:cityname/cart/",
    name: "Cart",
    component: () => import("@/views/Cart.vue"),
    meta: {
      requireAuth: true,
      requireInfo : true,
    },
  },
  {
    //搜索结果
    path: "/:cityname/search/",
    name: "Search",
    component: () => import("@/views/Search.vue")
  },
  {
    //每日行情
    path: "/:cityname/marketquotation/",
    name: "MarketQuotation",
    component: () => import("@/views/MarketQuotation.vue"),
  },
  {
    //商品详情
    path: "/:cityname/goodsdetail/",
    name: "GoodsDetail",
    component: () => import("@/views/GoodsDetail.vue"),
  },
  {
    //购物车->确认订单
    path: "/:cityname/confirmorder/",
    name: "ConfirmOrder",
    component: () => import("@/views/ConfirmOrder.vue"),
  },
  {
    //购物车->订单提交结果
    path: "/:cityname/confirmorderresult/",
    name: "ConfirmOrderResult",
    component: () => import("@/views/ConfirmOrderResult.vue"),
  },
  {
    path: "/:cityname/channel/login/",//渠道商登录
    name: "ChannelLogin",
    component: () => import("@/views/channel/Login.vue"),
    meta: {
      channelPath: true,
    }
  },
  {
    path: "/:cityname/channel/register/",//渠道商注册
    name: "ChannelRegister",
    component: () => import("@/views/channel/Register.vue"),
    meta: {
      channelPath: true,
    }
  },
  {
    path: "/:cityname/channel/password/",//渠道商忘记密码
    name: "ChannelPassword",
    component: () => import("@/views/channel/Password.vue"),
    meta: {
      channelPath: true,
    }
  },
  {
    path: "/:cityname/channel/completeinfo/",//渠道商完善页面
    name: "CompleteInfo",
    component: () => import("@/views/channel/CompleteInfo.vue"),
    meta: {
      channelPath: true,
      requireAuth: true,
    }
  },
  {
    path: "/:cityname/channel/channelgoods/",//渠道商发布列表页
    name: "ChannelGoods",
    component: () => import("@/views/channel/ChannelGoods.vue"),
    meta: {
      channelPath: true,
      requireAuth: true,
    }
  },
  {
    path: "/:cityname/channel/publishgoods/",//渠道商发布商品
    name: "PublishGoods",
    component: () => import("@/views/channel/PublishGoods.vue"),
    meta: {
      channelPath: true,
      requireAuth: true,
    }
  },
  {
    path: "/:cityname/sellerrechargetest/", //测试充值页
    name: "Sellerrechargetest",
    component: () => import("@/views/usercenter/Sellerrechargetest.vue"),
    meta: {
      requireAuth: false,
      requireInfo : true,
    }
  },
  {
    path: "/:cityname/webpayresult/", //充值成功通知
    name: "WebpayResult",
    component: () => import("@/views/usercenter/WebpayResult.vue"),
    meta: {
      requireAuth: false,
      requireInfo : true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
