// 自定义数组方法
if (typeof Array.prototype.isCustom === "undefined") {

    Array.prototype.isCustom = true;
    /**
     * 清空数组
     * @return 数组
     */
    Array.prototype.customClear = function () {

        this.length = 0;

        return this;
    };

    /**
     * 覆盖数组原有数据
     * @param arr 新数组
     * @return 数组
     */
    Array.prototype.customCover = function (arr) {

        this.customClear();
        this.push(...arr);

        return this;
    }
}

