import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission'
import 'amfe-flexible'
import "./common/utils/customArrayFunction";
import "./common/utils/customDateFunction";
import { WebUtil } from "./common/utils/webUtil";
import { WebLib } from "./common/utils/weblib"
import { SystemUtil } from "./common/utils/utils";
import vueWechatAuthPlugin from "./common/utils/wechatAuth"
import '@vant/touch-emulator';
import VConsole from "vconsole";

import { Button, Dialog, Toast, Notify, Popup, Swipe, SwipeItem, Icon, Tag, Image, CountDown, ConfigProvider, Field, NumberKeyboard, Checkbox, CheckboxGroup, List, Badge, Lazyload} from 'vant'
import {WECHAT_APPID, WECHAT_REDIRECT} from "./config/app";

const app = createApp(App)

// 全局引入工具类 (组件中使用 this.$WebUtil)
app.config.globalProperties.$WebUtil = WebUtil;
app.config.globalProperties.$WebLib = WebLib;
app.config.globalProperties.$SystemUtil = SystemUtil;

if(SystemUtil.getWeiXinVersion() != "") {
    // let vconsole = new VConsole();
}

// 全局过滤器
// app.config.globalProperties.$request = request
vueWechatAuthPlugin.redirect_uri = WECHAT_REDIRECT;

app.use(store)
app.use(router)
app.use(Button) //注册button组件
   .use(Dialog) 
   .use(Toast)
   .use(Notify)
    .use(Popup)
    .use(Swipe)
    .use(SwipeItem)
    .use(Icon)
    .use(Tag)
    .use(Image)
    .use(CountDown)
    .use(ConfigProvider)
    .use(Field)
    .use(NumberKeyboard)
    .use(CheckboxGroup)
    .use(Checkbox)
    .use(List)
    .use(Badge)
    .use(vueWechatAuthPlugin, {appid: WECHAT_APPID})
    .use(Lazyload, {lazyComponent: true});

app.mount('#app')

