import store from "../../store"

export const WebLib = {

    /**
     * 获取订单的描述
     * @param {String} orderstatus
     */
    getSellerOrderStatusDesc: function (orderstatus) {
        var desc = {};
        desc.buttontitle = "未知";//后台按钮文本描述
        desc.desc = "未知";//后端显示的状态描述
        desc.webdesc = "未知";//前端显示的状态描述
        desc.todesc = "未知";//后端改变到该状态的描述

        switch (orderstatus) {
            case "0":
                desc.buttontitle = "重置";
                desc.desc = "待批准";
                desc.webdesc = "已下单";
                desc.todesc = "把订单还原到待批准状态";
                break;

            case "1":
                desc.buttontitle = "批准";
                desc.desc = "已批准";
                desc.webdesc = "配货中";
                desc.todesc = "批准该订单";
                break;

            case "2":
                desc.buttontitle = "配货";
                desc.desc = "已配货";
                desc.webdesc = "送货中";
                desc.todesc = "订单已配货";
                break;

            case "3":
                desc.buttontitle = "送达";
                desc.desc = "已送达";
                desc.webdesc = "已收货";
                desc.todesc = "订单已送达";
                break;

            case "4":
                desc.buttontitle = "归档";
                desc.desc = "已归档";
                desc.webdesc = "已归档";
                desc.todesc = "订单已归档";
                break;

            case "5":
                desc.buttontitle = "结束";
                desc.desc = "已结束";
                desc.webdesc = "已结束";
                desc.todesc = "订单已结束";
                break;

            case "-1":
                desc.buttontitle = "作废";
                desc.desc = "系统作废";
                desc.webdesc = "系统作废";
                desc.todesc = "作废该订单";
                break;

            case "-2":
                desc.buttontitle = "作废";
                desc.desc = "商家作废";
                desc.webdesc = "商家作废";
                desc.todesc = "取消该订单";
                break;

            default:
                return null;
                break;
        }

        return desc
    },

    /**
     * 获取订单收款打折类型描述
     * @param {String} discountType
     */
    getSellerOrderDiscountTypeDesc: function (discountType) {
        var desc = "未知";
        switch (discountType) {
            case '0':
                desc = "无折扣";
                break;

            case '1':
                desc = "满减";
                break;

            case '2':
                desc = "满折";
                break;

            case '3':
                desc = "晚送";
                break;

            case '4':
                desc = "其他";
                break;

            case '5':
                desc = "商品减免";
                break;
        }

        return desc;
    },

    getFeedBackDesc: function (fbtype) {
        var desc = "";
        switch (fbtype) {
            case '0':
                desc = "信息咨询";
                break;

            case '1':
                desc = "投诉建议";
                break;

            case '2':
                desc = "新品建议";
                break;

            case '3':
                desc = "供应商资料";
                break;

            default:
                desc = "未知类型";
                break;
        }

        return desc;
    },

    /**
     * 获取商品销售类型对应文本(爆款、秒杀、特价
     * @param item
     * @returns {string}
     */
    getGoodsType: function (item, index = 0) {

        let desc = "";
        let type = [
            {key: "gspspattr1", desc: "特价"},
            {key: "gspspattr2", desc: "爆款"},
            {key: "gspspattr3", desc: "秒杀"},
            {key: "isMaiZeng", desc: "买赠"},
        ];
        if ((typeof item[type[index]["key"]] != "undefined") && item[type[index]["key"]] == 1) {
            desc = type[index]["desc"];
        }

        return desc;
    },

    /**
     * 计算商品的每单位成本
     * @param  gid            商品ID
     * @param  gspData        数据来源
     */
    getGoodsSingleCostPrice: function (gid, gspData) {

        if (!gspData[gid]) {
            return null;
        }

        var singleCostPrice = {
            "area": [],
            "unit": "斤"
        };


        var tmpPrice = 0;

        for (let i in gspData[gid]) {
            if (gspData[gid][i].gspinsale != 1) {
                continue;
            }


            if (gspData[gid][i].goodsgrossloadunit != "") {
                singleCostPrice["unit"] = gspData[gid][i].goodsgrossloadunit;
            }

            if (gspData[gid][i].goodsgrossload > 0) {
                tmpPrice = parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload);

                if (tmpPrice != parseInt(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                    tmpPrice = tmpPrice.toFixed(1);
                    if (tmpPrice != parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                        tmpPrice = Math.ceil(tmpPrice * 10) / 10;
                    }
                }

                singleCostPrice["area"].push(tmpPrice);
            }

            if (gspData[gid][i].goodsgrossloadmax > 0 && gspData[gid][i].goodsgrossloadmax != gspData[gid][i].goodsgrossload) {
                tmpPrice = parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossloadmax);

                if (tmpPrice != parseInt(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                    tmpPrice = tmpPrice.toFixed(1);
                    if (tmpPrice != parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                        tmpPrice = Math.ceil(tmpPrice * 10) / 10;
                    }
                }

                singleCostPrice["area"].push(tmpPrice);
            }

        }
        if (singleCostPrice["area"].length > 1) {
            singleCostPrice["area"].sort(function (a, b) {
                return a - b;
            });
        }

        return singleCostPrice;
    },

    /**
     * 计算商品规格的每单位成本
     * @param  gid            商品ID
     * @param  gspid          商品规格ID
     * @param  gspData        数据来源
     */
    getGoodsSpecificationSingleCostPrice: function (gid, gspid, gspData) {
        if (!gspData[gid]) {
            return null;
        }

        var singleCostPrice = {
            "area": [],
            "unit": "斤",
            "singleprice": 0,
            "primeprice": 0,
            "sellergradediscount": 100,
        };

        var tmpPrice = 0;
        for (var i in gspData[gid]) {

            if (gspData[gid][i].gspinsale != 1) {
                continue;
            }
            if (gspData[gid][i].gspid != gspid) {
                continue;
            }

            if (gspData[gid][i].goodsgrossloadunit != "") {
                singleCostPrice["unit"] = gspData[gid][i].goodsgrossloadunit;
                singleCostPrice["singleprice"] = gspData[gid][i].singleprice;
                singleCostPrice["primeprice"] = gspData[gid][i].primeprice;
                singleCostPrice["sellergradediscount"] = gspData[gid][i].sellergradediscount;
            }

            if (gspData[gid][i].goodsgrossload > 0) {

                tmpPrice = parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload);

                if (tmpPrice != parseInt(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                    tmpPrice = tmpPrice.toFixed(1);
                    if (tmpPrice != parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                        tmpPrice = Math.ceil(tmpPrice * 10) / 10;
                    }
                }

                singleCostPrice["area"].push(tmpPrice);
            }

            if (gspData[gid][i].goodsgrossloadmax > 0 && gspData[gid][i].goodsgrossloadmax != gspData[gid][i].goodsgrossload) {

                tmpPrice = parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload);

                if (tmpPrice != parseInt(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                    tmpPrice = tmpPrice.toFixed(1);
                    if (tmpPrice != parseFloat(gspData[gid][i].singleprice / gspData[gid][i].goodsgrossload)) {
                        tmpPrice = Math.ceil(tmpPrice * 10) / 10;
                    }
                }

                singleCostPrice["area"].push(tmpPrice);
            }
            break;
        }

        return singleCostPrice;
    },

    /**
     * 库存
     * @param goodsItem 商品详情
     * @returns {string}
     */
    storageStr(goodsItem, single = false) {

        let str = "";

        if(goodsItem.storage < 0) return "无限库存";
        return "库存" + goodsItem.storage + goodsItem.goodsunit;
        // 只需要直接显示库存就可以了
        /*if (goodsItem.gspinsalenum == 1 || single) {

            if (goodsItem.storage < 0) {

                str = "无限库存";
            } else {

                if (goodsItem.issnatchgoods != 0) {

                    str = "仅剩" + goodsItem.storage + goodsItem.goodsunit;
                } else if (goodsItem.storage >= 10) {

                    str = "仅剩" + goodsItem.storage + goodsItem.goodsunit;
                } else {

                    str = "库存紧张";
                }
            }
        } else {

            str = "限量供应";
        }

        return str;*/
    },

    /**
     * 计算商品每单位价格
     * @param goodsItem
     * @param goodsSpecifications
     * @param type int 1: 商品, 2: 规格
     * @returns {string}
     */
    unitPriceStr(goodsItem, goodsSpecifications, type = 1) {

        let str = "";

        let singleCostPriceData;
        if (type === 1) {
            singleCostPriceData = this.getGoodsSingleCostPrice(goodsItem.gid, goodsSpecifications);
        } else {
            singleCostPriceData = this.getGoodsSpecificationSingleCostPrice(goodsItem.gid, goodsItem.gspid, goodsSpecifications);
        }

        if (singleCostPriceData != null && singleCostPriceData["area"].length > 0) {

            if (singleCostPriceData.length === 1) {

                str = "约"+singleCostPriceData["area"][0]+"/"+singleCostPriceData["unit"];
            } else {

                str = "约"+singleCostPriceData["area"][0]+"起/"+singleCostPriceData["unit"];
            }
        }

        return str;
    },

    // 计算价格
    priceStr(goodsItem) {

        let str = "";

        if (store.getters.showGoodsPrice.showText) {
            return store.getters.showGoodsPrice.showText;
        }

        // 最低价不等于最高价
        if (goodsItem.minsingleprice !== goodsItem.maxsingleprice) {

            str = goodsItem.minsingleprice + "<span style='font-size:0.32rem;'>起</span>";

            // 兼容没有singleprice的情况(每日行情)
        } else if (typeof goodsItem.singleprice !== "undefined") {

            // uncertainsingleprice不确定价格
            if (parseInt(goodsItem.uncertainsingleprice) === 1) {

                str = "约" + goodsItem.singleprice;
            } else {

                str = goodsItem.singleprice;
            }
        } else {

            str = goodsItem.minsingleprice;
        }

        return str;
    },

    checkAlipayOrTxPay: function () {
        var myUrlInfo = window.location.href.split("?");
        if ((myUrlInfo[0].indexOf("/plusin/php/txpay/") >= 0 || myUrlInfo[0].indexOf("/plusin/php/txpayfws/") >= 0) && SystemUtil.getAlipayVersion() != "") {
            //微信支付界面,但是却在支付宝打开
            var newUrl = myUrlInfo[0].replace("txpay", "alipay");
            if (myUrlInfo.length > 1) {
                newUrl = newUrl + "?" + myUrlInfo[1];
            }
            SystemUtil.gotoUrl(newUrl);
        } else if (myUrlInfo[0].indexOf("/plusin/php/alipay/") >= 0 && SystemUtil.getWeiXinVersion() != "") {
            //支付宝界面,但是却在微信打开
            var newUrl = myUrlInfo[0].replace("alipay", "txpay");
            if (myUrlInfo.length > 1) {
                newUrl = newUrl + "?" + myUrlInfo[1];
            }
            SystemUtil.gotoUrl(newUrl);
        }
    }
};

export default {
    WebLib,
}


