import router from "./router"
import store from "./store"
import Cache from "@common/utils/cache"
import {getLoginInfo,autoLoginByWx} from "@/api/login"
import {checkSubSite} from "@/api/default"
import { getChannelLoginInfo } from "@/api/channel"
import {LOGIN_ACCOUNTKEY, SUBSITE_NAME, WECHAT_REDIRECT} from './config/app';
import vueWechatAuthPlugin from "@/common/utils/wechatAuth"
import { SystemUtil } from "@/common/utils/utils"
import Cookies from "js-cookie"
import { Toast } from "vant"
import VConsole from "vconsole";


router.beforeEach(async (to, from, next) => {

    if(to.fullPath && to.fullPath != "/" && to.fullPath.indexOf("/login") < 0) {
        Cache.set("redirectUrl", to.fullPath, 60);
    }
    if( to.params.cityname != "" ) {

        let subSiteName = to.params.cityname;

        // 验证分站
        if (SUBSITE_NAME.indexOf(subSiteName) >= 0) {
            store.commit("app/setSubSiteName", subSiteName)
        } else {
            // 无效分站，跳转到默认分站
            SystemUtil.gotoUrl("/");
            // window.location.href = window.location.origin + "/" + store.getters.subSiteName;
            return;
        }
        /*await checkSubSite({cityName: to.params.cityname})
            .then((res) => {
                if(!!res) {
                    let subSiteName = res.subSiteName;
                    store.commit("app/setSubSiteName", subSiteName)
                    if(subSiteName != to.params.cityname) {
                        window.location.href = window.location.origin + "/" + store.getters.subSiteName;
                    }
                }
            });*/


        if ( SystemUtil.getWeWorkVersion() == "" &&
            SystemUtil.getWeiXinVersion() != "" &&
            vueWechatAuthPlugin.authUrl && !(Cookies.get("msmallOkguo_v2_comApp_openidwx")))
        {
            const loginUrl = SystemUtil.getWxLoginUrl(WECHAT_REDIRECT + to.fullPath);
            console.log(loginUrl)
            vueWechatAuthPlugin.redirect_uri = loginUrl
            if(to.query.code) {
                //微信授权自动登录
                await autoLoginByWx({code:to.query.code},"正在登录...")
                    .then((res) => {
                        if(res.accountKey && store.getters.accountKey == "") {
                            store.commit("user/SET_ACCOUNTKEY", res.accountKey)
                            Cache.set(LOGIN_ACCOUNTKEY, res.accountKey)
                        }
                    })
                    .catch((res) => {
                        // Toast.fail("授权登录失败！！！");
                        // return;
                    })
            } else {
                window.location.href = vueWechatAuthPlugin.authUrl;
                return;
            }
        }
    }

    //渠道商相关路由
    if( to.meta.hasOwnProperty("channelPath") && to.meta.channelPath ) {
        store.commit("user/SET_CHANNEL_PATH", 1)
        await getChannelLoginInfo(params)
            .then((res) => {
                if(res != null) {
                    if (res.accountKey && store.getters.accountKey == "") {
                        store.commit("user/SET_ACCOUNTKEY", res.accountKey)
                        Cache.set(LOGIN_ACCOUNTKEY, res.accountKey)
                    }
                    if(Object.keys(res.loginInfo).length > 0) {
                        store.commit("user/SET_CHANNEL_STATUS", 1)
                        if(to.name == "ChannelLogin") {
                            next({
                                path: "/" + store.getters.subSiteName + "/channel/channelGoods/?ispublish=1"
                            })
                            return false;
                        }
                        next()
                    } else {
                        console.log("渠道商未登录!!!")
                        if (to.meta.requireAuth) {
                            next({
                                path: "/"+store.getters.subSiteName+"/channel/login/"
                            })
                        } else {
                            next()
                        }
                    }
                }
            });

        return false;
    }

    //自动获取登录用户信息
    const params = {};
    if(to.meta.requireInfo){
        params.getSellerName = true;
    }
    await getLoginInfo(params)
        .then((res) => {
            if(res != null) {

                store.commit("user/SET_ISSALEANDNOTINSYSOPENTIME", res.isSaleAndNotInSysOpenTime);
                store.commit("user/SET_SHOWGOODSPRICE", res.showGoodsPrice);
                store.commit("app/SET_CURRENT_TIMES", res.currentTimes);
                store.commit("user/SET_ACCESSCONTROL", res.accessControl);

                if(res.accountKey && store.getters.accountKey == "") {
                    store.commit("user/SET_ACCOUNTKEY", res.accountKey)
                    Cache.set(LOGIN_ACCOUNTKEY, res.accountKey)
                }

                if(Object.keys(res.loginInfo).length > 0) {
                    store.commit("user/SET_LOGINSTATUS", 1)
                    store.commit("user/SET_USERINFO", res.loginInfo)

                    //登录状态下没有填写认证信息
                    if(to.meta.requireInfo && res.loginInfo.sellername == ""){
                        next({
                            path: "/"+store.getters.subSiteName+"/sellercompleteinfo/"
                        })
                    }else{
                        if(store.getters.subSiteName != res.loginInfo.subsitename) {
                            //不是当前分站，自动跳转到属于自己的分站
                            let _url = window.location.href
                            let pathname = window.location.pathname;
                            if(pathname == "/"){
                                _url = window.location.origin + "/"+store.getters.subSiteName+pathname;
                            }
                            _url = _url.replace("/"+store.getters.subSiteName, "/"+res.loginInfo.subsitename)
                            store.commit("app/setSubSiteName", res.loginInfo.subsitename)
                            window.location.href = _url
                        }else{
                            //已登录状态下的访问重定向到首页
                            let pathname = window.location.pathname;
                            if( pathname.indexOf("/login") >= 0 ||
                                pathname.indexOf("/register") >= 0 ||
                                pathname.indexOf("/password") >= 0
                            ){
                                window.location.href = window.location.origin+"/"+store.getters.subSiteName+"/";
                            }else{
                                if(res.loginInfo.istestaccount == 1) {
                                    let vconsole = new VConsole();
                                }
                                next()
                            }
                        }
                    }
                }else {
                    console.log("未登录!!!")
                    store.dispatch("user/fedLogOut")
                    if (to.meta.requireAuth) {
                        next({
                            path: "/"+store.getters.subSiteName+"/login/",
                        })
                    } else {
                        next()
                    }
                }
            }else{
                console.log("未登录-!!!-")
                if (to.meta.requireAuth) {
                    next({
                        path: "/"+store.getters.subSiteName+"/login/",
                    })
                } else {
                    next()
                }
            }
        });

})
