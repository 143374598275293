import {
	DEFAULT_SUBSITENAME
} from '../config/app';

const getters = {
    //user
    accountKey: state => state.user.accountKey,
    isLogin: state => state.user.isLogin,
    userInfo: state => state.user.userInfo || {},
    isChannelLogin: state => state.user.isChannelLogin,
    isChannelPath: state => state.user.isChannelPath,
    cartNum: state => {

        let str = "";
        if (state.user.cartNum > 0) {
            str = state.user.cartNum;
        }

        return str;
    },
    cartAnimation: state => state.user.cartAnimation,
    showGoodsPrice: state => state.user.showGoodsPrice,
    isSaleAndNotInSysOpenTime: state => state.user.isSaleAndNotInSysOpenTime,
    accessControl: state => state.user.accessControl,

    //app
    subSiteName: state => state.app.subSiteName || DEFAULT_SUBSITENAME,
    currentTimes: state => state.app.currentTimes || 0,
}

export default getters