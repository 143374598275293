import {
	LOGIN_STATUS,
	LOGIN_ACCOUNTKEY
} from '../../config/app';
import Cache from '../../common/utils/cache';
import Cookies from "js-cookie"

const state = {
	accountKey: Cookies.get("msaccountKey") || Cache.get(LOGIN_ACCOUNTKEY) || "",
	isLogin: Cache.get(LOGIN_STATUS) || 0,
	userInfo: {},
	isChannelLogin: 0,
	isChannelPath:0,
	cartNum: 0, // 购物车商品种类数量
	cartAnimation: false, // 加入购物车动画(设置为true时，动画开始，动画结束以后会重置为false
	showGoodsPrice: {}, // 价格显示
	isSaleAndNotInSysOpenTime: 0, // 销售不在商城开放时间登陆商家
	accessControl: {}, //访问限制
};

const mutations = {
	SET_ACCOUNTKEY: (state, token) => {
		state.accountKey = token
	},
	SET_USERINFO: (state, userInfo) => {
		state.userInfo = userInfo
	},
	SET_LOGINSTATUS: (state, status) => {
		Cache.set(LOGIN_STATUS, status, 86400*7)
		state.isLogin = status
	},
	SET_CHANNEL_STATUS: (state, status) => {
		state.isChannelLogin = status
	},
	SET_CHANNEL_PATH: (state, status) => {
		state.isChannelPath = status
	},
	SET_CARTNUM: (state, cartNum) => {
		state.cartNum = cartNum;
	},
	SET_CARTANIMATION: (state, cartAnimation) => {
		state.cartAnimation = cartAnimation;
	},
	SET_SHOWGOODSPRICE: (state, showGoodsPrice) => {
		state.showGoodsPrice = showGoodsPrice;
	},
	SET_ISSALEANDNOTINSYSOPENTIME: (state, isSaleAndNotInSysOpenTime) => {
		state.isSaleAndNotInSysOpenTime = isSaleAndNotInSysOpenTime;
	},
	SET_ACCESSCONTROL: (state, accessControl) => {
		state.accessControl = accessControl;
	},
};

const actions = {
	// 登出
	fedLogOut({ commit }) {
		// 设置不同的登录状态
		commit("SET_CARTNUM", 0);
		commit("SET_LOGINSTATUS", 0);
		Cache.clear(LOGIN_STATUS);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};