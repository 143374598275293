import {h} from 'vue'

/**
 * 引入远程js
 * props src 远程js路径
 */
export default {
    name: "remoteJs",
    render() {

        return h('script', {type: 'text/javascript', src: this.src});
    },
    props: {
        src: {type: String, required: true},
    },
}