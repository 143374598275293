import store from '../../store';
const qs = require("qs")

//工具类封装
export const SystemUtil = {

    /**
     * 获取地址参数
     * @param {*} url 
     */
    getUrlParam(url) {
        if ( !url ) {
			url = window.location.href;
		}
		var paramStrings = url.split("?");
		var param = [];
		var keyvalues = [];
		var tmpKeyValue;
		if(paramStrings.length >=2)
		{
			keyvalues = paramStrings[1].split("&");
			for(var i=0;i<keyvalues.length;i++)
			{
				tmpKeyValue = keyvalues[i].split("=");
				if(tmpKeyValue.length < 2){ continue;}
				param[tmpKeyValue[0]] = decodeURI(tmpKeyValue[1]);
				
				if(param[tmpKeyValue[0]].indexOf("#") >=0)
				{
					tmpKeyValue = param[tmpKeyValue[0]].split("#");
					param[tmpKeyValue[0]] = tmpKeyValue[0];
				}
			}
		}
		
		return param;
    },

    htmlspecialchars(html) {
        if(!html){return "";}
		html = html.replace(/"/g , "&quot;");//"
		html = html.replace(/</g , "&lt;");//<
		html = html.replace(/>/g , "&gt;");//>
		html = html.replace(/&/g , "&amp;");//&
		
		return html;
    },

    /**
     * 获取URL路径的文件名
     * 注意,只有域名的时候会报错,例如:"http://www.abc.com",修改为"http://www.abc.com/"即可
     * @param {*} url 
     * @return [文件名,扩展名,URL?参数,完整文件名]
     */
    getUrlFileName(url) {
        if(!url || url == ""){ url = window.location.href;}
		
		url = url.replace(/http:\/\//ig,'');
		url = url.replace(/https:\/\//ig,'');

		var urlInfo = url.split("?");
		var urlInfoArr = urlInfo[0].split("/");
		var urlInfoParam = (urlInfo.length >1)?urlInfo[1]:"";
		var urlFile = urlInfoArr[urlInfoArr.length-1];
		
		var urlFileInfo = [];
		if(urlFile == "")
		{
			urlFileInfo[0] = "/";
			urlFileInfo[1] = "";
			urlFileInfo[2] = urlInfoParam;
			urlFileInfo[3] = "/";
		}else
		{
			urlFileInfo = urlFile.split(".");
			if(urlFileInfo.length <2){ urlFileInfo[1] = "";}
			urlFileInfo[2] = urlInfoParam;
			urlFileInfo[3] = urlFile;
		}
		
		return urlFileInfo;
    },

    /**
     * 输出对象的属性
     * 
     * @param {*} obj 
     * @returns 
     */
    printobject(obj) {
        var msg = ""
		for(var key in obj)
		{
			msg += key+"=>"+obj[key]+"\r\n";	
		}
		return msg;
    },

    /**
     * 获取当前微信版本号,如果不是微信下,返回空字符串
     * @returns 版本号
     */
    getWeiXinVersion() {
        var agents = navigator.userAgent.split(" ");
		var wxversion = "";
		var agentstr = "";
		for(var i=0;i<agents.length;i++)
		{
			agentstr = agents[i].toLowerCase();
			if(agentstr.indexOf("micromessenger")>=0)
			{
				var vinfo = agentstr.split("/");
				if(vinfo.length != 2){ continue;}
				wxversion = vinfo[1];
				
				break;
			}
		}
		
		return wxversion;
    },

    /**
     * 获取当前企业微信版本号,如果不是企业微信下,返回空字符串
     * @returns 版本号
     */
    getWeWorkVersion() {
        var agents = navigator.userAgent.split(" ");
		var wxversion = "";
		var agentstr = "";
		for(var i=0;i<agents.length;i++)
		{
			agentstr = agents[i].toLowerCase();
			if(agentstr.indexOf("wxwork")>=0)
			{
				var vinfo = agentstr.split("/");
				if(vinfo.length != 2){ continue;}
				wxversion = vinfo[1];

				break;
			}
		}

		return wxversion;
    },

    /**
     * 获取当前支付宝版本号,如果不是支付宝下,返回空字符串
     * @returns 版本号
     */
    getAlipayVersion() {
        var agents = navigator.userAgent.split(" ");
		var alipayVersion = "";
		var agentstr = "";
		for(var i=0;i<agents.length;i++)
		{
			agentstr = agents[i].toLowerCase();
			if(agentstr.indexOf("alipayclient")>=0)
			{
				var vinfo = agentstr.split("/");
				if(vinfo.length != 2){ continue;}
				alipayVersion = vinfo[1];
				
				break;
			}
		}
		
		return alipayVersion;
    },

    /**
     * 获取当前易信版本号,如果不是易信下,返回空字符串
     * @returns 版本号
     */
    getYiXinVersion() {
        var agents = navigator.userAgent.split(" ");
		var yxversion = "";
		var agentstr = "";
		for(var i=0;i<agents.length;i++)
		{
			agentstr = agents[i].toLowerCase();
			if(agentstr.indexOf("yixin")>=0)
			{
				var vinfo = agentstr.split("/");
				if(vinfo.length != 2){ continue;}
				yxversion = vinfo[1];
				
				break;
			}
		}
		
		return yxversion;
    },

    /**
     * 获取当前农行APP版本号,如果不是,返回空字符串
     * @returns 版本号
     */
    getABCBANKVersion() {
        var agents = navigator.userAgent.split(" ");
		var bankabcversion = "";
		var agentstr = "";
		for(var i=0;i<agents.length;i++)
		{
			agentstr = agents[i].toLowerCase();
			if(agentstr.indexOf("bankabc")>=0)
			{
				var vinfo = agentstr.split("/");
				if(vinfo.length != 2){ continue;}
				bankabcversion = vinfo[1];
				break;
			}
		}

		return bankabcversion;
    },

    /**
     * 判断当前的浏览器是不是考拉商圈
     * @returns boolean
     */
    isKlsqBrowser() {
        var agents = navigator.userAgent.toLowerCase()
		if(agents.indexOf("koalacsq")>=0)
		{
			return true;
		}
		return false;
    },

    /**
     * 判断是不是手机浏览器
     */
    isMobileBrowser() {
        var isIphone = (navigator.userAgent.toLowerCase().indexOf("iphone") >=0);
		var isAndroid = (navigator.userAgent.toLowerCase().indexOf("android") >=0);
		return isIphone||isAndroid;
    },
    
    /**
     * 判断是不是苹果系统 
     */
    isIos() {
        var isIos = (navigator.userAgent.toLowerCase().indexOf("iphone") >=0);
		
		return isIos;
    },

    /**
     * 判断是不是数字
     */
    isNumeric(num) {
        if(!isNaN(num)){
            return true;
        }else{
            return false;
        }
    },

    /**
     * 检查手机号
     * @param {*} mobile 
     * @returns 
     */
    checkMobile(mobile) {
        if(mobile === null || mobile ===undefined){ return "手机号无效";}
		if(mobile == ""){ return "请输入手机号";}
		
		if(mobile.length != 11)
		{
			return "手机号码必须是11位";
		}
		
		if(mobile.substring(0,1) != '1')
		{
			return "手机号码必须以数字1开头";
		}
		
		var result = mobile.match(/[^\d]/);
		if(result)
		{
			return "手机号码必须全是数字";
		}
		
		return "";
    },

	hasContains(arr,value) {
		let len = arr.length;
		while(len--) {
			if(arr[len] === value) {
				return true;
				break;
			}
		}
		return false;
	},

	/**
	 * 填充
	 * @param target 目标字符串
	 * @param length 长度
	 * @param fill 填充值
	 * @returns {string}
	 */
	customPadStart(target, length = 2, fill = '0') {

		return target.toString().padStart(length, fill)
	},

	/**
	 * 跳转
	 * @param gourl
	 * @param autoCompletionSubSiteName boolean 是否自动补全分站
	 */
	gotoUrl (gourl, autoCompletionSubSiteName = true) {

		if(gourl == ""){ return;}

		let url = "";

		// 完整路径
		if (gourl.indexOf("http") == 0 || gourl.indexOf("//") == 0) {
			url = gourl;
			// 相对、绝对路径
		} else {
			// 补全路径
			url = this.autoCompletionUrl(gourl, autoCompletionSubSiteName);
		}

		window.location.href = url;
	},

	/**
	 * 设置当前地址栏路径(不跳转)
	 * @param url 地址栏路径: 相对路径, 绝对路径(会自动补全url), 完整路径(需要相同host)
	 */
	setUrl(url) {

		if (typeof window.history.pushState !== "function") {
			return false;
		}

		// 完整路径
		if (url.indexOf("http") == 0 || url.indexOf("//") == 0) {


			// 完整路径跟当前host不同则不设置
			if (url.indexOf(window.location.host) === -1) {
				return false;
			}

			// 绝对路径
		} else if (url.indexOf("/") == 0) {

			// 补全路径
			url = this.autoCompletionUrl(url);
		}

		window.history.replaceState("", "", url);
		return true;
	},

	/**
	 * 自动补全url (补全 域名、 分站、 "/")
	 * @param url 待补全的url
	 * @param autoCompletionSubSiteName boolean 是否自动补全分站
	 * @returns {*} 补全后的url
	 *
	 */
	autoCompletionUrl(url, autoCompletionSubSiteName = true) {

		// 不是完整路径
		if (url.indexOf("http") !== 0 && url.indexOf("//") !== 0) {

			url = url.replace(/^\/?/, "/"); // 填充左边斜杆
			// 补全分站
			if (autoCompletionSubSiteName && url.indexOf("/" + store.getters.subSiteName) === -1) {
				url = "/" + store.getters.subSiteName.replace(/\/$/, "") + url;
			}

			// 补全域名
			url = window.location.origin + url;
		}

		// 自动补全 '/'
		if (url.indexOf("?") >= 0) {

			// ? 前面
			url = url.replace(/\/?\?/, "/?");
		} else if (url.indexOf("#") >= 0) {

			// # 前面
			url = url.replace(/\/?#/, "/#");
		} else if (url[url.length - 1] !== "/") {

			// 结尾
			url += "/";
		}

		return url;
	},

	/**
	 * 微信授权回调地址去掉一些不必要的参数
	 * @param url
	 * @returns {*}
	 */
	getWxLoginUrl (url) {
		const parseUrl = qs.parse(url.split("?")[1])
		let loginUrl
		if (parseUrl.code && parseUrl.state) {
			delete parseUrl.code
			delete parseUrl.state
			loginUrl = `${url.split("?")[0]}?${qs.stringify(parseUrl)}`
		} else {
			loginUrl = url
		}

		return loginUrl;
	},

	/**
	 * 将url和参数拼接成完整地址
	 * @param {string} url url地址
	 * @param {Json} data json对象
	 * @returns {string}
	 */
	getUrl(url, data){
		//看原始url地址中开头是否带?，然后拼接处理好的参数
		return url += (url.indexOf('?') < 0 ? '?' : '&') + this.getParam(data)
	},
	/**
	 * 传入对象返回url参数
	 * @param {Object} data {a:1}
	 * @returns {string}
	 */
	getParam(data){
		let url = '';
		for(var k in data){
			let value = data[k] !==undefined ? data[k] : '';
			url += `&${k}=${encodeURIComponent(value)}`
		}
		return url ? url.substring(1) : ''
	},
}

export default {
    SystemUtil,
}