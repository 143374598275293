import {
	EXPIRE
} from '../../config/app';

/**
 * 缓存操作类
 */
class Cache {

    constructor(handler) {
        this.storage = window.localStorage;
		this.cacheExpire = 'MALL-APP:TAG';
		this.clearOverdue();
	}

    /**
	 * 获取当前时间戳
	 */
	time() {
		return Math.round(new Date() / 1000);
	}

    /**
	 * 字符串转时间戳
	 * @param {Object} expiresTime
	 */
	strTotime(expiresTime){
		let expires_time = expiresTime.substring(0, 19);
		expires_time = expires_time.replace(/-/g, '/');
		return Math.round(new Date(expires_time).getTime() / 1000);
	}

    /**
	 * 设置过期时间缓存
	 * @param {Object} key
	 * @param {Object} expire
	 */
	setExpireCacheTag(key, expire) {
		expire = expire !== undefined ? expire : EXPIRE;
		if (typeof expire === 'number') {
			let tag = this.storage.getItem(this.cacheExpire), newTag = [],newKeys = [];
			if (tag && typeof tag === 'string') {
				tag = JSON.parse(tag)
				if(tag.length) {
					newTag = tag.map(item => {
						newKeys.push(item.key);
						if (item.key === key) {
							item.expire = expire === 0 ? 0 : this.time() + expire;
						}
						return item;
					});
				}
			} 
			if (!newKeys.length || newKeys.indexOf(key) === -1) {
				newTag.push({
					key: key,
					expire: expire === 0 ? 0 : this.time() + expire
				});
			}
			if(newTag.length) {
				newTag = JSON.stringify(newTag)
			}
			this.storage.setItem(this.cacheExpire, newTag);
		}
	}

	/**
	 * 缓存是否过期,过期自动删除
	 * @param {Object} key
	 * @param {Object} $bool true = 删除,false = 不删除
	 */
	getExpireCache(key, $bool) {
		try {
			let tag = this.storage.getItem(this.cacheExpire),time = 0,index = false;
			if (tag && typeof tag === 'string') {
				tag = JSON.parse(tag);
				if(!tag.length) return false;
				tag.map((item,i) => {
					if(item.key === key){
						time = item.expire
						index = i
					}
				});
				if (time) {
					let newTime = parseInt(time);
					if (time && time < this.time() && !Number.isNaN(newTime)) {
						if ($bool === undefined || $bool === true) {
							this.storage.removeItem(key);
							if(index !== false){
								tag.splice(index,1)
								this.storage.removeItem(this.cacheExpire,tag);
							}
						}
						return false;
					} else
						return true;
				} else {
					return !!this.storage.getItem(key);
				}
			}
			return false;
		} catch (e) {
			return false;
		}
	}

	/**
	 * 设置缓存
	 * @param {Object} key
	 * @param {Object} data
	 */
	set(key, data, expire) {
		if (data === undefined) {
			return true;
		}
		if (typeof data === 'object')
			data = JSON.stringify(data);
		try {
			this.setExpireCacheTag(key, expire);
			return this.storage.setItem(key, data);
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	/**
	 * 检测缓存是否存在
	 * @param {Object} key
	 */
	has(key) {
		this.clearOverdue();
		return this.getExpireCache(key);
	}

	/**
	 * 获取缓存
	 * @param {Object} key
	 * @param {Object} $default
	 * @param {Object} expire
	 */
	get(key, $default, expire) {
		this.clearOverdue();
		try {
			let isBe = this.getExpireCache(key);
			let data = this.storage.getItem(key);
			// return isBe;
			if (data && isBe) {
				if (typeof $default === 'boolean')
					return JSON.parse(data);
				else
					return data;
			} else {
				if (typeof $default === 'function') {
					let value = $default();
					this.set(key, value, expire);
					return value;
				} else {
					this.set(key, $default, expire);
					return $default;
				}
			}
		} catch (e) {
			return null;
		}
	}

	/**
	 * 删除缓存
	 * @param {Object} key
	 */
	clear(key) {
		try {
			let cacheVaule = this.storage.getItem(this.cacheExpire),
				index = false;
			if (cacheVaule && typeof cacheVaule === 'string') {
				cacheVaule = JSON.parse(cacheVaule);
				if(cacheVaule.length) {
					cacheVaule.map((item, i) => {
						if (item.key === key) {
							index = i;
						}
					});
	
					if (index !== false) {
						cacheVaule.splice(index, 1);
					}
					this.storage.setItem(this.cacheExpire, JSON.stringify(cacheVaule));
				}
			}
			return this.storage.removeItem(key);
		} catch (e) {
			return false;
		}
	}

	/**
	 * 清除过期缓存
	 */
	clearOverdue() {
		let cacheVaule = this.storage.getItem(this.cacheExpire),
			time = this.time(),
			newBeOverdueValue = [],
			newTagValue = [];

		if (cacheVaule && typeof cacheVaule === 'string') {
			cacheVaule = JSON.parse(cacheVaule);
			if(cacheVaule.length) {
				cacheVaule.map(item => {
					if (item) {
						if ((item.expire !== undefined && item.expire > time) || item.expire === 0) {
							newTagValue.push(item);
						} else {
							newBeOverdueValue.push(item.key);
						}
					}
				});
			}
		}
		//保存没有过期的缓存标签
		if (cacheVaule != null && newTagValue.length !== cacheVaule.length) {
			this.storage.setItem(this.cacheExpire, JSON.stringify(newTagValue));
		}
		//删除过期缓存
		if (newBeOverdueValue.length > 0) {
			newBeOverdueValue.forEach(k => {
				this.storage.removeItem(k);
			})
		}
	}
}

export default new Cache;