import store from '../../store';

export const WebUtil = {

    cityName: "",
    isInstance: false,

    _init() {
        if (!this.isInstance) {
            this.cityName = "/" + store.getters.subSiteName.replace(/\/$/, "");
            this.isInstance = true;
        }
    },

    /**
     * 将图片路径替换成对应分站
     * @param url 要替换的路径
     * @param cityNameParam 分站名 例: /ka
     * @returns {string}
     */
    formatUploadImageUrlToWebUrl:function(url, cityNameParam = "")
    {
        this._init();

        if (!url) {
            return "";
        }

        var serverIndexInfo = url.match(/\[#imageserver_(\d)\](?:.*?)/);
        var cityName = this.cityName;

        if (cityNameParam) {
            cityName = cityNameParam.replace(/\/$/,"");
        }

        if(serverIndexInfo && serverIndexInfo.length >=2)
        {
            var serverListItem = SystemUploadServerList[serverIndexInfo[1]];

            var serverCDNDomain = serverListItem.cdndomain?serverListItem.cdndomain:serverListItem.domain;//优先使用cdn域名，没有的话再使用domain域名
            var serverDomain = (serverListItem && serverCDNDomain)?serverCDNDomain:"";
            serverDomain = serverDomain+cityName;
            url = url.replace(serverIndexInfo[0],serverDomain);

            if(SystemEnv.isHttps == true)
            {
                url = url.toLowerCase().replace("http://","https://");
            }else
            {
                url = url.toLowerCase().replace("https://","http://");
            }
        }
        return url;
    },

    /**
     * 把文本内容转成Html格式
     * @param {String} content
     */
    text2Html:function(content)
    {
        if(!content){ return "";}

        content = content.replace(/\r/g , "<br />");

        return content;
    }
};

export default {
    WebUtil,
}