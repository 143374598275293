import axios from 'axios';
import { Toast, Dialog, Notify } from 'vant'
import store from '../../store'
import router from "../../router"
import { SystemUtil } from "@/common/utils/utils"
import {
    V_ERRORCODE_NONE,
	V_ERRORCODE_NORMAL_ERROR,
    V_ERRORCODE_NORMAL_SELLERNEEDLOGIN,
} from '../../config/app';
var qs = require("qs");

// 默认请求地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers['token'] = store.getters.accountKey || ''
axios.defaults.timeout = 3 * 60 * 1000

//添加响应拦截器
axios.interceptors.response.use(
    res => {

        //响应拦截器
        if (res.status === 200) {
            if(res.data == null) {
                Toast.fail('返回数据有异常:' + JSON.stringify(res.data));
            } else if(res.data.code && res.data.code == V_ERRORCODE_NORMAL_ERROR) {
                Dialog({title: "系统提示", message: res.data.message});
                return Promise.reject(res.data);
            } else if(res.data.code && res.data.code == V_ERRORCODE_NORMAL_SELLERNEEDLOGIN) {
                SystemUtil.gotoUrl("/login/");
            } else if (res.data.code != V_ERRORCODE_NONE) {
                return Promise.reject(res.data);
            }

            return res.data;
        } else {
            let _url = res.config.url
            Notify({ type: 'danger', message: '由于网络不通畅,请求[' + _url + ']失败' });
        }
    },
    error => {

        return Promise.reject(error);
    }
);

//添加请求拦截器
axios.interceptors.request.use(function (config) {
    config.baseURL += "/" + store.getters.subSiteName + "/api";
    
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

const request = {
    /**
     * get请求
     * @param url 请求url
     * @param data 数据
     * @param config 配置
     * @param loadingConfig 显示Toast.loading (false:不显示, string: Toast.loading文字提示, object: 将直接应用于Toast.loading);
     * @returns {Promise<unknown>}
     */
    get(url, data = {}, config = {}, loadingConfig = false) {

        var urlParam = SystemUtil.getUrlParam();
        if(urlParam.clientkey) {
            url = SystemUtil.getUrl(url,{clientkey:urlParam.clientkey});
        }
        if(urlParam.switchnewmall) {
            url = SystemUtil.getUrl(url,{switchnewmall:urlParam.switchnewmall});
        }
        return new Promise((resolve, reject) => {

            let toastObj = this.showLoading(loadingConfig)

            axios.get(url, {params: data, ...config})
                .then(res => {
                    this.hideLoading(toastObj, loadingConfig);
                    resolve(res.data)
                })
                .catch(err => {
                    this.hideLoading(toastObj, loadingConfig);
                    reject(err)
                })
        })
    },
    /**
     * post请求
     * @param url 请求url
     * @param data 数据
     * @param config 配置
     * @param loadingConfig 显示Toast.loading (false:不显示, string: Toast.loading文字提示, object: 将直接应用于Toast.loading);
     * @returns {Promise<unknown>}
     */
    post(url, data = {}, config = {}, loadingConfig = false) {
        var urlParam = SystemUtil.getUrlParam();
        if(urlParam.clientkey) {
            url = SystemUtil.getUrl(url,{clientkey:urlParam.clientkey});
        }
        if(urlParam.switchnewmall) {
            url = SystemUtil.getUrl(url,{switchnewmall:urlParam.switchnewmall});
        }

        return new Promise((resolve, reject) => {

            let toastObj = this.showLoading(loadingConfig)

            axios.post(url, qs.stringify(data), config)
                .then(res => {

                    this.hideLoading(toastObj, loadingConfig);
                    resolve(res.data)
                })
                .catch(err => {
                    this.hideLoading(toastObj, loadingConfig);
                    reject(err)
                })
        })
    },

    /**
     * 显示Toast.loading
     * @param loadingConfig false:不显示, string: Toast.loading文字提示, object: 将直接应用于Toast.loading);
     */
    showLoading(loadingConfig = false) {

        if (loadingConfig === false) {
            return false;
        } else if (typeof loadingConfig === "object") {

            return Toast.loading(loadingConfig);
        } else {
            if (typeof loadingConfig !== "string") {
                loadingConfig = "";
            }

            return Toast.loading({
                message: loadingConfig,
                forbidClick: true,
                duration: 0,
            });
        }
    },

    /**
     * 隐藏Toast.loading
     * @param toastObj Toast.loading 返回的对象
     * @param loadingConfig
     */
    hideLoading(toastObj = false, loadingConfig = false) {

        if (toastObj !== false && loadingConfig !== false) {

            toastObj.clear();
        }
    },

    /**
     * 手动构建错误对象
     * @param message 错误信息
     */
    createErrorMsg(message = "",) {

        var obj = {
            code: 1,
            error: "错误提示",
            message: message,
        }

        return obj;
    },

    /**
     * 调用错误回调(手动调用)
     * @param message
     * @param failCallBack
     */
    fail(message, failCallBack) {

        if (typeof failCallBack == "function") {

            var errorObj = this.createErrorMsg(message);
            failCallBack(errorObj);
        }
    }
}

export default request