// 自定义日期方法
if (typeof Date.prototype.myFormat === "undefined") {

    //时间格式花函数
    Date.prototype.myFormat = function(formatstr , timestamp)
    {
        //如果传时间戳，则更改时间
        if(timestamp != undefined)
        {
            timestamp = parseInt(timestamp)*1000;
            this.setTime(timestamp);
        }


        if(!formatstr)
        {
            throw new Error("Date.myFormat 参数错误");
        }
        var item = {
            //4 位数字完整表示的年份
            "Y+":this.getFullYear(),
            //数字表示的月份，有前导零(01 到 12)
            "m+":((this.getMonth()+1).toString().length <2 ? "0"+(this.getMonth()+1):(this.getMonth()+1)),
            //月份中的第几天，有前导零的 2 位数字(01~31)
            "d+":(this.getDate().toString().length <2 ? "0"+this.getDate():this.getDate()),
            //小时，24 小时格式，有前导零(00 到 23)
            "H+":(this.getHours().toString().length <2 ? "0"+this.getHours():this.getHours()),
            //有前导零的分钟数(00 到 59)
            "i+":(this.getMinutes().toString().length <2 ? "0"+this.getMinutes():this.getMinutes()),
            //秒数，有前导零(00 到 59)
            "s+":(this.getSeconds().toString().length <2 ? "0"+this.getSeconds():this.getSeconds()),


            //数字表示的月份，没有前导零(1 到 12)
            "n+":this.getMonth()+1,
            //月份中的第几天，没有前导零(1 到 31)
            "j+":this.getDate(),
            //星期中的第几天，1（表示星期一）到 7（表示星期天）
            "N+":( this.getDay()==0 ? 7:this.getDay()),
            //星期中的第几天，0（表示星期天）到 6（表示星期六）
            "w+":this.getDay(),
            //。。。未完待续
        };

        for(var k in item)
        {
            if(new RegExp("("+ k +")").test(formatstr))
            {
                formatstr = formatstr.replace(RegExp.$1, item[k]);
            }
        }

        return formatstr;
    }
}